import { Injectable } from '@angular/core';
import { PhotoRoute } from 'src/app/core/models/photo-route.model';

@Injectable({
  providedIn: 'root',
})
export class ProjectsService {
  all = [
    new ProjectDescription({
      category: 'cat-1',
      title: 'Ansamblul rezidențial e-Novation Loft',
      images: ['assets/images/proiecte/loft/1.jpeg'],
      gallery: [
        { isActive: true, urlImage: 'assets/images/proiecte/loft/1.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/loft/2.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/loft/3.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/loft/4.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/loft/5.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/loft/6.jpg' },
      ],
      client: 'ELECTROGRUP',
      time: '3 luni',
      description: `Proiectul E-novation Loft este situat în orașul Cluj-Napoca, Cele două blocuri vor cuprinde 35 apartamente de lux (ce vor fi livrate în stadiu complet de finisare), iar cele 4 case pe 2 nivele aflate în incinta complexului vor acomoda 4 familii. Toate apartamentele vor fi livrate în stadiu complet de finisare, cu toate băile complet utilate și pardoselile din toate încăperile în forma finală. Fiecare apartament va avea 2 sau 3 dormitoare precum și un living spațios la care este atașată o bucătărie deschisă. De asemenea, toate apartamentele (cu o singură excepție) vor beneficia de 3 băi, balcoane generoase, spații pentru dressing-uri dar și de posibilitatea de a achiziționa câte o boxă subterană. Fiecare bloc va avea 5 nivele locative (Parter + 4 etaje), iar pe fiecare etaj (cu excepția etajului 4) sunt situate câte 4 apartamente. Fiecare apartament va beneficia de cel puțin 2 locuri de parcare, din care cel puțin unul în parcarea subterană.`,
      solution: `
        Realizarea instalațiilor de curenți slabi și tari din interiorul imobilului cu soluția tehnică oferită, ca fiind cea mai eficientă, care a constat, în realizarea traseelor și circuitelor din pereți și sapă, în 4 etape, înainte de tencuială și după tencuială: <br><br>
        <ul class="list-check-circle primary">
          <li>Etapa 1 – trasarea și marcarea, realizarea carotărilor și slițurilor, fixarea tuburilor pâna la placa și a dozelor, precum și a cofretelor;</li>
          <li>Etapa 2 – tragerea, realizarea și pozarea circuitelor de curenți slabi și tari în pereți și pe placă;</li>
          <li>Etapa 3 – montajul aparatajului și echiparea tablourilor după finisări;</li>
          <li>Etapa 4 – verificare, teste, probe și punerea în funcțiune a instalațiilor.</li>
        </ul>
      `,
    }),
    new ProjectDescription({
      category: 'cat-1',
      title:
        'Imobil cu funcție mixtă situat pe str. Teodor Mihali, nr. 45-47, 49-51',
      images: [
        'assets/images/proiecte/TERAX/1 - coperta.jpg',
        'assets/images/proiecte/TERAX/2 - coperta.jpg',
      ],
      client: 'TERAX',
      gallery: [
        { isActive: true, urlImage: 'assets/images/proiecte/TERAX/1 - coperta.jpg' },
        { isActive: true, urlImage: 'assets/images/proiecte/TERAX/2 - coperta.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/3.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/4.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/5.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/6.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/7.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/8.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/9.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/10.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/11.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/12.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/13.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/14.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/15.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/16.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/17.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/18.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/19.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/20.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/21.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/22.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/23.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/24.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/25.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/26.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/27.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/28.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/29.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/30.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/31.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/32.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/33.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/34.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/35.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/36.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/37.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/38.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/39.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/40.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/41.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/42.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/43.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/44.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/45.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/46.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/47.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/TERAX/48.jpeg' },
      ],
      time: '12 luni',
      description: `Imobilul cu funcție mixtă este situat pe strada Teodor Mihali, nr.45-51, în orașul Cluj-Napoca. În apropierea complexului locativ sunt centre de business, se află centrul comercial Iulius Mall (la doar 650 de metri), funcționează sucursale bancare, sunt magazine, cafenele și restaurante. În apropiere de apartamente se află corturile de tenis „Match Ball Teniss Club” și este sala de fitness „Gold Gym”. Sunt de parcurs 450 de metri până la Grădinița „Degețica”, la 650 de metri se află Colegiul Tehnic de transporturi „Transilvania” și de la complexul rezidențial în numai 3 minute pietonal se ajunge până la FSEGA.
      Imobilul cu funcție mixtă este o clădire de apartamente excepțional proiectată într-un stil arhitectură ultra modernă și cu un aspect extrem de atractiv. Blocul de apartamente este construit din materiale calitative pe cadre din beton armat cu un Parter și plus 5 Etaje. Cu un sistem termic de 10 cm grosime este placată clădirea locativă și un ambient termic confortabil este asigurat de către tâmplăria exterioară instalată la bloc, aceasta este de marca Gealan, are 6 camere termice cu 3 garnituri și cu armatură zincată, unde sunt instalate 3 foi de sticlă termopană de tip „4 anotimpuri”, ce au grosimea de 52 mm și feroneria este de marca Mako cu sistem de micro ventilare. Transportul personal al locatarilor poate fi parcat la cele 136 de locuri de parcare subterane din incinta complexului locativ organizate pe două nivele și pentru vizitatori există organizate și parcări supraterane și sunt amenajate 72 de parcări pentru biciclete. De asemenea complexul locativ dispune de 2 stații de încărcare pentru autovehiculele electrice.

      `,
      solution: `
      Realizarea instalațiilor de curenti slabi și tari din interiorul și exteriorul imobilului cu soluția tehnică oferită, ca fiind cea mai eficientă, care a constat, în realizarea traseelor și circuitelor din pereți, șapă și jgheab în 3 etape, înainte de tencuială și după tencuială: <br><br>
        <ul class="list-check-circle primary">
          <li>Etapa 1 – trasarea și marcarea, realizarea carotărilor și slițurilor, fixarea și pozarea jgheaburilor, tuburilor precablate și a circuitelor până la tablouri și doze de derivație, fixarea dozelor – înainte de tencuială;</li>
          <li>Etapa 2 – montajul tablourilor echipate, legături în doze – după tencuială;</li>
          <li>Etapa 3 – montajul aparatajului și racordarea circuitelor - după finisări;</li>
          <li>Etapa 4 – verificare, teste, probe și punerea în funcțiune a instalațiilor și sistemelor.</li>
        </ul>
      `,
    }),
    new ProjectDescription({
      category: 'cat-1',
      title: 'West City Tower',
      images: ['assets/images/proiecte/tower/1 - coperta.jpg',
               'assets/images/proiecte/tower/2 - coperta.jpg',
               'assets/images/proiecte/tower/3 - coperta.jpeg',
               'assets/images/proiecte/tower/4 - coperta.jpg'
      ],
      gallery: [
        { isActive: true, urlImage: 'assets/images/proiecte/tower/1 - coperta.jpg' },
        { isActive: true, urlImage: 'assets/images/proiecte/tower/2 - coperta.jpg' },
        { isActive: true, urlImage: 'assets/images/proiecte/tower/3 - coperta.jpeg' },
        { isActive: true, urlImage: 'assets/images/proiecte/tower/4 - coperta.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/tower/5.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/tower/6.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/tower/7.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/tower/8.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/tower/9.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/tower/10.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/tower/11.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/tower/12.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/tower/13.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/tower/14.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/tower/15.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/tower/16.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/tower/17.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/tower/18.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/tower/19.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/tower/20.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/tower/21.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/tower/22.jpg' },
      ],
      client: 'MD CLIMATERM',
      time: '12 luni',
      description: `West City Tower este situat în orașul Cluj-Napoca, pe Calea Florești nr. 139. <strong>Cu o arhitectură circulară și foarte multe suprafețe vitrate, acesta este cel mai înalt bloc de locuințe din România. Se ridică desupra orașului cu 25 de etaje, 5 etaje de parcare</strong>, spațiu de joacă pentru copii, 182 apartamente cu panoramă deosebită cu 1, 2, 3 camere si 4 penthouse-uri cu 5 camere. Imobilul dispune de două lifturi de interior ce coboară până în parking, la cele două subsoluri și trei demisoluri. La exterior, pentru o priveliște care îți taie respirația, cladirea a fost echipată cu un lift panoramic unic  în Cluj-Napoca.
            <br><br>Structura de rezistenţă a clădirii este proiectată în conformitate cu cele mai înalte standarde şi cu respectarea tuturor obligatiilor impuse de legislaţia şi normativele tehnice în vigoare, fiind o structură de beton armat, cu inchidere perimetrală din caramidă şi compartimentări uşoare.Partea de fundaţie a structurii de rezistenţă este realizată în sistem top-down, lucrare de mare complexitate și cu soluții tehnice inovatoare.`,
      solution: `
            Realizarea instalațiilor de curenți slabi și tari din interiorul imobilului cu soluția tehnica oferită, ca fiind cea mai eficientă, care a constat, in realizarea traseelor și circuitelor din pereți și sapă, în 4 etape, înainte de tencuială și după tencuială: <br><br>
            <ul class="list-check-circle primary">
                <li>Etapa 1 – trasarea și marcarea, realizarea carotărilor și slițurilor, fixarea tuburilor pâna la placa și a dozelor, precum și a cofretelor;</li>
                <li>Etapa 2 – tragerea, realizarea și pozarea circuitelor de curenți slabi și tari în pereți și pe placă;</li>
                <li>Etapa 3 – montajul aparatajului și echiparea tablourilor după finisări;</li>
                <li>Etapa 4 – Verificare, teste, probe si punerea in functiune a instalațiilor</li>
            </ul>`,
    }),
    new ProjectDescription({
      category: 'cat-1',
      title: 'Record Park',
      images: ['assets/images/proiecte/record_park/1.jpg',
                'assets/images/proiecte/record_park/2.jpg',
              ],
      gallery: [
        {
          isActive: true,
          urlImage: 'assets/images/proiecte/record_park/1.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/record_park/2.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/record_park/3.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/record_park/4.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/record_park/5.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/record_park/6.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/record_park/7.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/record_park/8.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/record_park/9.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/record_park/10.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/record_park/11.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/record_park/12.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/record_park/13.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/record_park/14.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/record_park/15.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/record_park/16.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/record_park/17.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/record_park/18.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/record_park/19.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/record_park/20.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/record_park/21.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/record_park/22.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/record_park/23.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/record_park/24.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/record_park/25.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/record_park/26.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/record_park/27.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/record_park/29.jpeg',
        },
      ],
      client: 'MD CLIMATERM',
      time: '18 luni',
      description: `În cartierul Mărăști din orașul Cluj-Napoca, pe strada Onisifor Ghibu, la numărul 20A, se găsește mândru amplasat ansamblul rezidențial „Record Park”. În complexul de locuințe este o atmosferă calmă cu multă liniște, deoarece până la locuințe nu ajunge zgomotul bulevardului și imobilele se află situate chiar pe malul Canalului Morii. Pe un teren de 17 300 mp este dezvoltat (în două faze) ansamblul rezidențial „Record Park” și în total complexul are construită o suprafață de 37 000 mp. În compoziția ansamblului sunt prevăzute două clădiri rezidențiale modern proiectate la nivelul celor mai exigente cerințe și la cele mai înalte standarde europene. Blocurile rezidențiale sunt compuse din Două Subsoluri plus Un Parter și au câte 6 Etaje locative. La construcție este folosit betonul armat de calitate superioară, în baza structurii de rezistență stau stâlpii din oțel, piloții secanți și planșeele. Scările locative sunt echipate cu cele mai bune ascensoare, care sunt alese de la producători cu renume și funcționează nu numai rapid dar și foarte liniștit. .Apartamentele din complexul rezidențial „Record Park” sunt ideale din toate punctele de vedere. Toate 236 unități locative sunt inteligent compartimentate și dispun de planimetrii bine gândite cu 2, 3, 4 camere și studio-uri elegante. Suprafața studio-urilor începe de la 45 de mp, proprietățile locative cu două camere au suprafața de la 56,6 mp până la 68,5 mp, locuințele confortabile cu scheme cu 3 camere vă bucură cu suprafața de la 79,5 mp până la 98,2 mp, iar cele mai spațioase locuințele dispun de 4 camere și sunt prezentate cu mărimea de 110 mp.`,
      solution: `
            Realizarea instalațiilor de curenți slabi și tari din interiorul imobilului cu soluția tehnica oferită, ca fiind cea mai eficientă, care a constat, in realizarea traseelor și circuitelor din pereți și sapă, în 4 etape, înainte de tencuială și după tencuială:
            <br><br><ul class="list-check-circle primary">
                <li>Etapa 1 – trasarea și marcarea, realizarea carotărilor și slițurilor, fixarea tuburilor pâna la placa și a dozelor, precum și a cofretelor;</li>
                <li>Etapa 2 – tragerea, realizarea și pozarea circuitelor de curenți slabi și tari în pereți și pe placă;</li>
                <li>Etapa 3 – montajul aparatajului și echiparea tablourilor după finisări;</li>
                <li>Etapa 4 – verificare, teste, probe și punerea în funcțiune a instalațiilor.</li>
            </ul>`,
    }),
    new ProjectDescription({
      category: 'cat-1',
      title: 'Scala Frunzisului',
      images: ['assets/images/proiecte/scala/1.webp'],
      gallery: [
        { isActive: true, urlImage: 'assets/images/proiecte/scala/1.webp' },
        { isActive: false, urlImage: 'assets/images/proiecte/scala/2.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/scala/3.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/scala/4.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/scala/5.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/scala/6.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/scala/7.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/scala/8.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/scala/9.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/scala/10.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/scala/11.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/scala/12.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/scala/13.jpg' },
      ],
      client: 'MD CLIMATERM',
      time: '15 luni',
      showInMenu: true,
      description: `SCALA Frunzişului este amplasat la intersecţia dintre cartierele Zorilor şi Mănăştur, în cadrul unui ansamblu de 6 blocuri ce beneficiază de drum privat, spaţii verzi, parcări subterane sau supraterane şi spaţiu de joaca individual. Are cel mai mare regim de înălţime din întreg ansamblul, 17 niveluri, ce beneficiază de panorame spectaculoase. Structura apartamentelor este de la 1 la 3 camere iar compartimentările sunt optimizate astfel încât să deservească toate funcţiunile necesare unor familii moderne: zone de zi generoase, suprafeţe vitrate ample şi terase spaţioase. Structura de rezistență este realizată din cadre de beton armat cu plăci dală, astfel eliminând grinzile inestetice din interiorul apartamentelor. Ansamblul beneficiază de 3 laturi fără vecini și o orientare ideală, gândită ca toate apartamentele să beneficieze de lumină și de o priveliște spectaculoasă asupra orașului.`,
      solution: `
            Realizarea instalațiilor de curenți slabi și tari din interiorul imobilului cu soluția tehnica oferită, ca fiind cea mai eficientă, care a constat, in realizarea traseelor și circuitelor din pereți și sapă, în 4 etape, înainte de tencuială și după tencuială:</li>
            <br><br><ul class="list-check-circle primary">
                <li>Etapa 1 – trasarea și marcarea, realizarea carotărilor și slițurilor, fixarea tuburilor pâna la placa și a dozelor, precum și a cofretelor;</li>
                <li>Etapa 2 – tragerea, realizarea și pozarea circuitelor de curenți slabi și tari în pereți și pe placă;</li>
                <li>Etapa 3 – montajul aparatajului și echiparea tablourilor după finisări;</li>
                <li>Etapa 4 – verificare, teste, probe și punerea în funcțiune a instalațiilor.</li>
            </ul>`,
    }),
    new ProjectDescription({
      category: 'cat-1',
      title: 'Estimo Residence',
      images: ['assets/images/proiecte/estimo/1 - coperta.jpeg',
               'assets/images/proiecte/estimo/2 - coperta.jpeg'
      ],
      gallery: [
        { isActive: true, urlImage: 'assets/images/proiecte/estimo/1 - coperta.jpeg' },
        { isActive: true, urlImage: 'assets/images/proiecte/estimo/2 - coperta.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/3.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/4.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/5.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/6.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/7.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/8.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/9.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/10.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/11.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/12.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/13.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/14.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/15.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/16.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/17.png' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/18.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/19.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/20.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/21.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/22.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/23.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/24.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/25.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/26.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/27.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/28.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/29.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/30.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/31.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/32.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/33.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/34.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/35.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/36.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/37.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/38.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/39.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/40.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/41.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/42.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/43.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/44.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/45.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/46.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/47.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/48.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/49.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/50.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/51.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/52.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/53.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/54.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/55.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/56.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/57.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/58.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/59.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/60.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/61.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/62.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/63.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/64.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/65.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/estimo/66.jpg' },
      ],
      client: 'ESTIMO',
      time: '5 luni',
      showInMenu: true,
      description: `Expansiunea rezidențială orientată către estul municipiului Cluj-Napoca integrează viitorul ansamblu rezidențial Estimo Residence ca factor emergent remarcabil. Poziționarea acestuia în imediata vecinătate a Aeroportului Internațional Avram Iancu, într-o zonă de dezvoltare înzestrată în prezent cu o dinamică urbanistică remarcabilă, Sânnicoară, ne promovează cu un produs imobiliar de succes. Estimo Residence beneficiază de un acces deosebit de facil din zona estică a orașului, din cartierul Mărăsti și Iulius Mall, obiectiv față de care ansamblul se situează la o distanță de numai 10 minute, fiind interconectat de acestea prin mijloacele de transport în comun. De asemenea, din punct de vedere al accesibilității, se remarcă faptul că ansamblul nostru rezidențial se află la o distanță de 2km față de Aeroportulul Internațional Avram Iancu Cluj-Napoca și la doar 1km de Centura de Est a Clujului, circulația periferică majoră ce facilitează atât accesul spre centrul orașului, precum și către zonele sale limitrofe de pe direcția nord-sud. Estimo Residence beneficiează de standarde înalte de infrastructură, după cum urmează: căi de circulație sistematizate și accesorizate cu zonă carosabilă dedicată și asfaltată, trotuare generoase, locuri de parcare indoor sub imobil, outdoor pe terenul amenajat în proximitate, spații verzi agrementate cu grădini de fațadă, iluminat public de proximitate, utilități edilitare complete, nu în ultimul rând integrând și spații de joacă.`,
      solution: `
            Realizarea instalațiilor de curenți slabi și tari din interiorul și exteriorul ansamblului de imobile cu soluția tehnică oferită, ca fiind cea mai eficientă, care a constat, în realizarea traseelor și circuitelor din pereți și sapă, în 3 etape, înainte de tencuială și după tencuială: 
            <br><br><ul class="list-check-circle primary">
                <li>Etapa 1 – trasarea și marcarea, realizarea carotărilor și slițurilor, fixarea și pozarea tuburilor precablate și a circuitelor pâna la tablouri și doze de derivație, fixarea dozelor, precum și a cofretelor neechipate, legături în doze – înainte de tencuială;</li>
                <li>Etapa 2 – montajul aparatajului și echiparea tablourilor după finisări;</li>
                <li>Etapa 3 – verificare, teste, probe și punerea în funcțiune a instalațiilor.</li>
            </ul>`,
    }),
    new ProjectDescription({
      category: 'cat-1',
      title: 'Școala Gimanzială “Avram Iancu” Beliș',
      images: ['assets/images/proiecte/belis/1 - coperta.jpg',
               'assets/images/proiecte/belis/2 - coperta.jpg',
               'assets/images/proiecte/belis/2 - coperta.jpg',
               'assets/images/proiecte/belis/4 - coperta.jpeg',
      ],
      gallery: [
        { isActive: true, urlImage: 'assets/images/proiecte/belis/1 - coperta.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/2 - coperta.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/3 - coperta.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/4 - coperta.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/5.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/6.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/7.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/8.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/9.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/10.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/11.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/12.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/13.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/14.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/15.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/16.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/17.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/18.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/19.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/20.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/21.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/22.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/23.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/24.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/25.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/26.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/27.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/28.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/29.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/30.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/31.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/32.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/33.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/34.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/35.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/36.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/37.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/38.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/39.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/40.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/41.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/42.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/43.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/44.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/45.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/46.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/47.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/belis/48.jpg' },

      ],
      client: 'JUNGE CONSTRUCT',
      time: '24 luni',
      description: `Scoala Gimnaziala “AVRAM IANCU” Belis este situata in Comuna Belis, Judetul Cluj aceasta a intrat in 1 iulie 2019 intr-un process de reabilitare, modernizare si dotare , in cadrul unui proiect, în valoare de un milion de euro, finanțat prin Programul Național de Dezvoltare Rurală. Proiectul a presupus extinderea corpului de clădire, înlocuirea completă a acoperișului, refacerea finisajelor interioare și exterioare, precum și dotarea clădirii cu un lift care să asigure accesul persoanelor cu dizabilități. Totodată, mobilierul a fost înlocuit și s-a realizat dotarea sălilor de clasă, laboratoarelor, birourilor și celorlalte spații cu toate materialele didactice și utilitățile necesare desfășurării activităților unei școli europene.`,
      solution: `Realizarea instalațiilor de curenți slabi și tari din interiorul și exteriorul ansamblului de imobile cu soluția tehnică oferită, ca fiind cea mai eficientă, care a constat, în realizarea traseelor și circuitelor din pereți și sapă, în 3 etape, înainte de tencuială și după tencuială: 
            <br><br><ul class="list-check-circle primary">
                <li>Etapa 1 – trasarea și marcarea, realizarea carotărilor și slițurilor, fixarea și pozarea tuburilor precablate și a circuitelor pâna la tablouri și doze de derivație, fixarea dozelor, precum și a cofretelor neechipate, legături în doze – înainte de tencuială;</li>
                <li>Etapa 2 – montajul aparatajului și echiparea tablourilor după finisări;</li>
                <li>Etapa 3 – verificare, teste, probe si punerea in functiune a instalațiilor sistemelor.</li>
            </ul>`,
    }),
    new ProjectDescription({
      category: 'cat-1',
      title: 'Școala Gimanzială Iara',
      images: ['assets/images/proiecte/iara/1 - coperta.jpg',
               'assets/images/proiecte/iara/2 - coperta.jpg'
      ],
      gallery: [
        { isActive: true, urlImage: 'assets/images/proiecte/iara/1 - coperta.jpg' },
        { isActive: true, urlImage: 'assets/images/proiecte/iara/2 - coperta.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iara/3.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iara/4.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iara/5.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iara/6.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iara/7.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iara/8.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iara/9.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iara/10.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iara/11.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iara/12.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iara/13.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iara/14.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iara/15.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iara/16.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iara/17.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iara/18.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iara/19.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iara/20.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iara/21.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iara/22.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iara/23.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iara/24.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iara/25.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iara/26.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iara/27.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iara/28.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iara/29.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iara/30.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iara/31.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iara/32.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iara/33.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iara/34.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iara/35.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iara/36.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iara/37.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iara/38.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iara/39.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iara/40.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iara/41.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iara/42.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iara/43.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iara/44.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iara/45.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iara/46.jpg' },
      ],
      client: 'JUNGE CONSTRUCT',
      time: '12 luni',
      description: `Scoala Gimnaziala Iara este situata in Comuna Iara Judetul Cluj, având în vedere legea educaţiei naţionale, autorităţile comunei Iara au insistat de a lungul timpului în formarea unui sistem educaţional eficient, care să satisfacă nevoile populaţiei. Astfel s-a asigurat o bază materială corespunzătoare studiului şi au fost dotate instituţii de învăţământ care să deservească populaţia şcolară a comunei.
            Infrastructura educaţională La nivelul comunei Iara există 4 unităţi educaţionale pentru ciclul primar şi gimnazial, precum şi grădiniţe. La Iara functioneaza toate nivelurile de invatamant,Scoala Gimnaziala Iara fiind unitate cu personalitate juridica avand arondata o structura Scoala Primara Agris. Spaţiul alocat Școlii Gimanziale Iara este dotat cu laboratoare (fizică-chimie, biologie), bibliotecă (12.000 volume) şi, cabinet multimedia (24 calculatoare). Proiectul a presupus extinderea corpului de clădire, înlocuirea completă a acoperișului, refacerea finisajelor interioare și exterioare, precum și dotarea clădirii cu un lift care să asigure accesul persoanelor cu dizabilități. Totodată, mobilierul a fost înlocuit și s-a realizat dotarea sălilor de clasă, laboratoarelor, birourilor și celorlalte spații cu toate materialele didactice și utilitățile necesare desfășurării activităților unei școli europene.`,
      solution: `Realizarea instalațiilor de curenți slabi și tari din interiorul și exteriorul ansamblului de imobile cu soluția tehnică oferită, ca fiind cea mai eficientă, care a constat, în realizarea traseelor și circuitelor din pereți și sapă, în 3 etape, înainte de tencuială și după tencuială: 
            <br><br><ul class="list-check-circle primary">
                <li>Etapa 1 – trasarea și marcarea, realizarea carotărilor și slițurilor, fixarea și pozarea tuburilor precablate și a circuitelor pâna la tablouri și doze de derivație, fixarea dozelor, precum și a cofretelor neechipate, legături în doze – înainte de tencuială;</li>
                <li>Etapa 2 – montajul aparatajului și echiparea tablourilor după finisări;</li>
                <li>Etapa 3 – verificare, teste, probe si punerea in functiune a instalațiilor sistemelor.</li>
            </ul>`,
    }),
    new ProjectDescription({
      category: 'cat-1',
      title: 'Grădiniță cu Program Prelungit Daniela',
      images: [
        'assets/images/proiecte/GRADINTA_CU_PROGRAM_PRELUNGIT_DANIELA/1 coperta.jpg',
        'assets/images/proiecte/GRADINTA_CU_PROGRAM_PRELUNGIT_DANIELA/2 coperta.jpg',
        'assets/images/proiecte/GRADINTA_CU_PROGRAM_PRELUNGIT_DANIELA/3 coperta.jpg',
        'assets/images/proiecte/GRADINTA_CU_PROGRAM_PRELUNGIT_DANIELA/4 coperta.jpg',
        'assets/images/proiecte/GRADINTA_CU_PROGRAM_PRELUNGIT_DANIELA/5 coperta.jpg',
      ],
      client: 'GRADINTA CU PROGRAM PRELUNGIT DANIELA',
      time: '8 luni',
      gallery: [
        {
          isActive: true,
          urlImage:
            'assets/images/proiecte/GRADINTA_CU_PROGRAM_PRELUNGIT_DANIELA/1 coperta.jpg',
        },
        {
          isActive: false,
          urlImage:
            'assets/images/proiecte/GRADINTA_CU_PROGRAM_PRELUNGIT_DANIELA/2 coperta.jpg',
        },
        {
          isActive: false,
          urlImage:
            'assets/images/proiecte/GRADINTA_CU_PROGRAM_PRELUNGIT_DANIELA/3 coperta.jpg',
        },
        {
          isActive: false,
          urlImage:
            'assets/images/proiecte/GRADINTA_CU_PROGRAM_PRELUNGIT_DANIELA/4 coperta.jpg',
        },
        {
          isActive: false,
          urlImage:
            'assets/images/proiecte/GRADINTA_CU_PROGRAM_PRELUNGIT_DANIELA/5 coperta.jpg',
        },
        {
          isActive: false,
          urlImage:
            'assets/images/proiecte/GRADINTA_CU_PROGRAM_PRELUNGIT_DANIELA/6.jpg',
        },
        {
          isActive: false,
          urlImage:
            'assets/images/proiecte/GRADINTA_CU_PROGRAM_PRELUNGIT_DANIELA/7.jpg',
        },
        {
          isActive: false,
          urlImage:
            'assets/images/proiecte/GRADINTA_CU_PROGRAM_PRELUNGIT_DANIELA/8.jpg',
        },
        {
          isActive: false,
          urlImage:
            'assets/images/proiecte/GRADINTA_CU_PROGRAM_PRELUNGIT_DANIELA/9.jpg',
        },
        {
          isActive: false,
          urlImage:
            'assets/images/proiecte/GRADINTA_CU_PROGRAM_PRELUNGIT_DANIELA/10.jpg',
        },
        {
          isActive: false,
          urlImage:
            'assets/images/proiecte/GRADINTA_CU_PROGRAM_PRELUNGIT_DANIELA/11.jpg',
        },
        {
          isActive: false,
          urlImage:
            'assets/images/proiecte/GRADINTA_CU_PROGRAM_PRELUNGIT_DANIELA/12.jpg',
        },
        {
          isActive: false,
          urlImage:
            'assets/images/proiecte/GRADINTA_CU_PROGRAM_PRELUNGIT_DANIELA/13.jpg',
        },
        {
          isActive: false,
          urlImage:
            'assets/images/proiecte/GRADINTA_CU_PROGRAM_PRELUNGIT_DANIELA/14.jpg',
        },
        {
          isActive: false,
          urlImage:
            'assets/images/proiecte/GRADINTA_CU_PROGRAM_PRELUNGIT_DANIELA/15.jpg',
        },
        {
          isActive: false,
          urlImage:
            'assets/images/proiecte/GRADINTA_CU_PROGRAM_PRELUNGIT_DANIELA/16.jpg',
        },
        {
          isActive: false,
          urlImage:
            'assets/images/proiecte/GRADINTA_CU_PROGRAM_PRELUNGIT_DANIELA/17.jpg',
        },
        {
          isActive: false,
          urlImage:
            'assets/images/proiecte/GRADINTA_CU_PROGRAM_PRELUNGIT_DANIELA/18.jpg',
        },
        {
          isActive: false,
          urlImage:
            'assets/images/proiecte/GRADINTA_CU_PROGRAM_PRELUNGIT_DANIELA/19.jpg',
        },
        {
          isActive: false,
          urlImage:
            'assets/images/proiecte/GRADINTA_CU_PROGRAM_PRELUNGIT_DANIELA/20.jpg',
        },
        {
          isActive: false,
          urlImage:
            'assets/images/proiecte/GRADINTA_CU_PROGRAM_PRELUNGIT_DANIELA/21.jpg',
        },
      ],
      description: `Grădinița cu Program Prelungit Daniela este situată în localitatea Florești Județul Cluj.  Grădinița cu Program Prelungit Daniela își desfășoară activitatea cu profesioniști tineri și entuziaști, care lucrează în permanență la standarde inalte, totul pentru educatia copiilor Dvs. La Gradinița Daniela copiii vor fi întotdeauna în siguranță și înconjurați de dragoste. Obiectivul general al proiectului îl constituie îmbunătăţirea calităţii infrastructurii unităților de educaţie preşcolară şi antepreșcolară, pentru asigurarea unui proces educaţional de nivel european şi creşterea participării populaţiei şcolare la procesul educational.`,
      solution: `Realizarea instalațiilor de curenți slabi și tari din interiorul și exteriorul ansamblului de imobile cu soluția tehnică oferită, ca fiind cea mai eficientă, care a constat, în realizarea traseelor și circuitelor din pereți și sapă, în 3 etape, înainte de tencuială și după tencuială: 
            <br><br><ul class="list-check-circle primary">
                <li>Etapa 1 – trasarea și marcarea, realizarea carotărilor și slițurilor, fixarea și pozarea tuburilor precablate și a circuitelor pâna la tablouri și doze de derivație, fixarea dozelor, precum și a cofretelor neechipate, legături în doze – înainte de tencuială;</li>
                <li>Etapa 2 – montajul aparatajului și echiparea tablourilor după finisări;</li>
                <li>Etapa 3 – verificare, teste, probe si punerea in functiune a instalațiilor sistemelor.</li>
            </ul>`,
    }),
    new ProjectDescription({
      category: 'cat-1',
      title:
        'Imobil mixt, amenajări exterioare, împrejmuire – str. Dâmboviței nr. 12-18, Cluj-Napoca',
      images: ['assets/images/proiecte/imobil_dambovite/1 - coperta.jpg'],
      client: 'MD CLIMATERM',
      time: '12 luni',
      gallery: [
        {
          isActive: true,
          urlImage: 'assets/images/proiecte/imobil_dambovite/1 - coperta.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/2.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/3.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/4.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/5.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/6.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/7.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/8.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/9.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/10.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/11.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/12.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/13.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/14.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/15.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/16.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/17.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/18.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/19.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/20.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/21.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/22.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/23.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/24.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/25.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/26.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/27.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/28.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/29.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/30.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/31.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/32.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/33.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/34.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/35.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/36.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/37.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/38.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/39.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/40.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/41.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/42.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/43.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/44.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/45.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/46.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/47.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/48.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/49.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/50.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/51.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/52.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/53.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/54.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/55.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/56.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/57.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/58.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/59.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/60.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/61.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/62.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/63.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/64.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/65.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/66.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/67.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/68.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/69.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/70.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/71.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/72.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/imobil_dambovite/73.jpg',
        },
      ],
      description: `Ansamblul rezidențial se afla în localitatea Cluj-Napoca judetul Cluj, noul Ansamblu Rezidențial va fi cu siguranța un reper al orasului și cel mai exclusivist loc din centrul cartierului Maraști. Arhitectura contemporană, spectaculoasă se combina cu un design elegant dând naștere unui complex cu adevarat unic! Ansamblul oferă spații generoase și compartimentări eficiente. Se remarcă prin calitatea materialelor folosite, amplasare și facilități. Are un regim de înalțime 2S+P+6E fiind construit dupa cele mai riguroase standarde în domeniu! Ansamblul dispune de apartamente cu suprafețe cuprinse între 37 mp utili(dormitor +living cu bucătărie) până la - 70mp utili(3 dormitoare decomandate plus living cu bucătărie) plus terase generoase . Gararea masinilor se face în cele doua subsoluri unde se găsesc peste 180 locuri de parcare. Deasemenea există posibilitatea achiziționării și a locurilor de parcare exterioare!`,
      solution: `Realizarea instalațiilor de curenți slabi și tari din interiorul imobilului cu soluția tehnica oferită, ca fiind cea mai eficientă, care a constat, in realizarea traseelor și circuitelor din pereți și sapă, în 4 etape, înainte de tencuială și după tencuială: 
            <br><br><ul class="list-check-circle primary">
                <li>Etapa 1 – trasarea și marcarea, realizarea carotărilor și slițurilor, fixarea tuburilor până la placa și a dozelor, precum și a cofretelor – înainte de tencuială;</li>
                <li>Etapa 2 – tragerea, realizarea și pozarea circuitelor de curenți slabi și tari în pereți și pe placă – dupa tencuială;</li>
                <li>Etapa 3 – montajul aparatajului și echiparea tablourilor - dupa finisări;</li>
                <li>Etapa 4 – verificare, teste, probe și punerea în funcțiune a instalațiilor.</li>
            </ul>`,
    }),
    new ProjectDescription({
      category: 'cat-1',
      title: 'UpTown Residence',
      images: ['assets/images/proiecte/uptown_romed/1 - coperta.jpeg',
               'assets/images/proiecte/uptown_romed/2 - coperta.jpg'
      ],
      gallery: [
        { isActive: true, urlImage: 'assets/images/proiecte/uptown_romed/1 - coperta.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/2 - coperta.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/3.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/4.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/5.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/6.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/7.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/8.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/9.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/10.jpg' },
        { isActive: true, urlImage: 'assets/images/proiecte/uptown_romed/11.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/12.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/13.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/14.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/15.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/16.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/17.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/18.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/19.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/20.jpg' },
        { isActive: true, urlImage: 'assets/images/proiecte/uptown_romed/21.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/22.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/23.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/24.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/25.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/26.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/27.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/28.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/29.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/30.jpg' },
        { isActive: true, urlImage: 'assets/images/proiecte/uptown_romed/31.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/32.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/33.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/34.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/35.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/36.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/37.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/38.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/39.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/40.jpg' },
        { isActive: true, urlImage: 'assets/images/proiecte/uptown_romed/41.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/42.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/43.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/44.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/45.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/46.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/47.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/48.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/49.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/50.jpg' },
        { isActive: true, urlImage: 'assets/images/proiecte/uptown_romed/51.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/52.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/53.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/54.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/55.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/56.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/57.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/58.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/59.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/60.jpeg' },
        { isActive: true, urlImage: 'assets/images/proiecte/uptown_romed/61.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/62.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/63.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/64.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/65.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_romed/66.jpeg' },
      ],
      client: 'ROMED',
      time: '12 luni',
      description:
        'UpTown Residence reprezintă un ansamblu de locuințe localizat într-una din cele mai atractive zone ale orașului Cluj-Napoca, pe Calea Turzii 188 colț cu Nicolae Steinhardt, la o distanță foarte mică față de Centru, cu acces facil din Bună Ziua, Eugen Ionesco și Calea Turzii. Suprafața desfășurată este de 12676 mp, structura: Beton armat monolit, an: 2017 iar arhitectura: Studio Axia.',
      solution: `
            Realizarea instalațiilor de curenți slabi și tari din interiorul și exteriorul imobilului cu soluția tehnică oferită, ca fiind cea mai eficientă, care a constat, în realizarea traseelor și circuitelor din pereți și șapă, în 3 etape, înainte de tencuială și după tencuială: 
            <br><br><ul class="list-check-circle primary">
                <li>Etapa 1 – trasarea și marcarea, realizarea carotărilor și slițurilor, fixarea și pozarea tuburilor precablate și a circuitelor până la tablouri și doze de derivație, fixarea dozelor, precum și a cofretelor neechipate, legături în doze – înainte de tencuială;</li>
                <li>Etapa 2 – montajul aparatajului și echiparea tablourilor - după finisări;</li>
                <li>Etapa 3 – verificare, teste, probe și punerea în funcțiune a instalațiilor și sistemelor.</li>
            </ul>`,
    }),
    new ProjectDescription({
      category: 'cat-1',
      title: 'UpTown Residence',
      images: ['assets/images/proiecte/uptown_inter/1 - coperta.jpeg',
               'assets/images/proiecte/uptown_inter/2 - coperta.jpg'
      ],
      gallery: [
        { isActive: true, urlImage: 'assets/images/proiecte/uptown_inter/1 - coperta.jpeg' },
        { isActive: true, urlImage: 'assets/images/proiecte/uptown_inter/2 - coperta.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/3.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/4.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/5.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/6.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/7.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/8.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/9.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/10.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/11.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/12.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/13.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/14.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/15.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/16.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/17.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/18.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/19.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/20.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/21.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/22.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/23.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/24.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/25.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/26.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/27.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/28.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/29.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/30.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/31.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/32.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/33.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/34.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/35.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/36.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/37.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/38.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/39.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/40.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/41.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/42.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/43.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/44.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/45.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/46.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/47.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/48.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/49.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/50.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/51.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/52.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/53.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/54.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/55.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/56.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/57.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/58.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/59.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/60.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/61.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/uptown_inter/62.jpg' },
      ],
      client: 'INTER INVEST GRUP',
      time: '12 luni',
      description:
        'UpTown Residence reprezintă un ansmablu de locuințe localizat într-una din cele mai atractive zone ale orașului Cluj-Napoca, pe Calea Turzii 188, colț cu Nicolae Steinhardt, la o distanță foarte mică față de Centru, cu acces facil din Bună Ziua, Eugen Ionesco și Calea Turzii. Suprafața desfășurată este de 12676 mp, structura: Beton armat monolit, an: 2017 iar arhitectura: Studio Axia.',
      solution: `
            Realizarea instalațiilor de curenti slabi și tari din interiorul și exteriorul imobilului cu soluția tehnică oferită, ca fiind cea mai eficientă, care a constat, în realizarea traseelor și circuitelor din pereți și șapă, în 3 etape, înainte de tencuială și după tencuială: 
            <br><br><ul class="list-check-circle primary">
                <li>Etapa 1 – trasarea și marcarea, realizarea carotarilor și slițurilor, fixarea și pozarea tuburilor precablate și a circuitelor până la tablouri și doze de derivație, fixarea dozelor, precum și a cofretelor neechipate, legături în doze – înainte de tencuială</li>
                <li>Etapa 2 – montajul aparatajului și echiparea tablourilor - după finisări</li>
                <li>Etapa 3 – verificare, teste, probe și punerea în funcțiune a instalațiilor și sistemelor</li>
            </ul>`,
    }),
    new ProjectDescription({
      category: 'cat-1',
      title:
        'Reparații capitale, Instalații electrice curenți tari și slabi – Parchetul de pe lângă Tribunalul Cluj',
      images: ['assets/images/proiecte/safesteel/1 - coperta.jpg'],
      gallery: [
        { isActive: true, urlImage: 'assets/images/proiecte/safesteel/1 - coperta.jpg' },
      ],

      client: 'SAFESTEEL',
      time: '24 luni',
      description:
        'Parchetul de pe lângă Tribunalul Cluj se află în orasul Cluj-Napoca, Calea Dorobanţilor nr. 2-4. Lucrări de modernizare și reparații capitale la instalații electrice de curenți tari și slabi.',
      solution: `
            Realizarea instalațiilor de curenti slabi și tari din interiorul și exteriorul imobilului cu soluția tehnică oferită, ca fiind cea mai eficientă, care a constat, în realizarea traseelor și circuitelor din pereți, șapă și jgheab în 3 etape, înainte de tencuială și după tencuială: 
            <br><br><ul class="list-check-circle primary">
                <li>Etapa 1 – trasarea și marcarea, realizarea carotărilor și slițurilor, fixarea și pozarea jgheaburilor, tuburilor precablate și a circuitelor până la tablouri și doze de derivație, fixarea dozelor, precum și a cofretelor echipate, legături în doze, montajul tablourilor echipate – înainte de tencuială;</li>
                <li>Etapa 2 – montajul aparatajului și racordarea circuitelor - după finisări;</li>
                <li>Etapa 3 – verificare, teste, probe și punerea în funcțiune a instalațiilor și sistemelor.</li>
            </ul>`,
    }),
    new ProjectDescription({
      category: 'cat-1',
      title:
        'Reabilitarea energetică cladirea veche a spitalului Orășenesc Huedin',
      images: ['assets/images/proiecte/huedin/1 - coperta.jpeg',
               'assets/images/proiecte/huedin/2 - coperta.jpeg',
               'assets/images/proiecte/huedin/3 - coperta.jpg',
      ],
      gallery: [
        { isActive: true, urlImage: 'assets/images/proiecte/huedin/1 - coperta.jpeg' },
        { isActive: true, urlImage: 'assets/images/proiecte/huedin/2 - coperta.jpeg' },
        { isActive: true, urlImage: 'assets/images/proiecte/huedin/3 - coperta.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/4.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/5.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/6.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/7.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/8.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/9.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/10.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/11.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/12.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/13.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/14.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/15.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/16.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/17.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/18.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/19.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/20.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/21.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/22.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/23.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/24.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/25.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/26.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/27.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/28.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/29.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/30.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/31.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/32.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/33.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/34.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/35.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/36.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/37.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/38.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/39.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/40.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/41.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/42.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/43.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/44.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/45.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/46.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/47.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/48.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/49.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/50.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/51.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/52.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/53.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/54.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/55.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/56.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/57.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/58.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/59.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/60.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/61.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/62.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/63.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/64.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/65.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/66.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/67.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/68.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/69.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/70.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/71.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/huedin/72.jpeg' },
      ],
      client: 'NORD CONFOREST',
      time: '24 luni',
      description:
        'Spitalul Orășenesc Huedin se află în orașul Huedin județul Cluj. Reabilitarea energetică clădirea veche a spitalului Orășenesc Huedin, execuția lucrărilor de construcție, furnizare și montaj echipamente/utilaje aferente lucrărilor, Obectivul general al proiectului este creșterea eficienței energetice a clădirii vechi a Spitalului Orășenesc Huedin pentru reducerea consumului energetic și îmbunătățirea calității serviciilor medicale oferite de Spitalul Orășenesc Huedin. Scopul reabilitării clădirii este de a muta secțiile ambulatorului de specialitate din cadrul spitalului într-o singură clădire pentru eficientizarea activității și asigurarea fluxurilor/condițiilor necesare funcționării acestuia.',
      solution: `
            Realizarea instalațiilor de curenți slabi și tari din interiorul și exteriorul imobilului cu soluția tehnică oferită, ca fiind cea mai eficientă, care a constat, în realizarea traseelor și circuitelor din pereți, șapă și jgheab în 3 etape, înainte de tencuială și după tencuială:
            <br><br><ul class="list-check-circle primary">
                <li>Etapa 1 – trasarea și marcarea, realizarea carotărilor și slițurilor, fixarea și pozarea jgheaburilor, tuburilor precablate și a circuitelor până la tablouri și doze de derivație, fixarea dozelor, precum și a cofretelor echipate, legături în doze, montajul tablourilor echipate – înainte de tencuială;</li>
                <li>Etapa 2 – montajul aparatajului și racordarea circuitelor - după finisări;</li>
                <li>Etapa 3 – verificare, teste, probe și punerea în funcțiune a instalațiilor și sistemelor.</li>
            </ul>`,
    }),
    new ProjectDescription({
      category: 'cat-2',
      title: 'Alimentarea cu energie electrică consumatori noi Hala 1 Heco',
      images: ['assets/images/proiecte/hala_heco/1 - coperta.jpg',
               'assets/images/proiecte/hala_heco/2 - coperta.jpg'
      ],
      client: 'ELECTROGRUP',
      time: '3 luni',
      gallery: [
        {
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/1 - coperta.jpg',
        },
        {
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/2 - coperta.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_heco/3.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_heco/4.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_heco/5.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_heco/6.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_heco/7.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_heco/8.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_heco/9.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_heco/10.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_heco/11.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_heco/12.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_heco/13.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_heco/14.jpg',
        },
        {
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/15.jpg',
        },
        {
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/16.jpg',
        },
        {
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/17.jpg',
        },
        {
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/18.jpg',
        },
        {
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/19.jpg',
        },
        {
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/20.png',
        },
        {
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/21.jpg',
        },
        {
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/22.jpg',
        }, {
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/23.jpg',
        }, {
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/24.jpg',
        }, {
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/25.jpg',
        }, {
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/26.jpg',
        }, {
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/27.jpg',
        }, {
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/28.jpg',
        }, {
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/29.jpg',
        }, {
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/30.jpg',
        }, {
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/31.jpg',
        }, {
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/32.jpg',
        }, {
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/33.jpg',
        }, {
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/34.jpg',
        }, {
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/35.jpeg',
        }, {
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/36.jpeg',
        }, {
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/37.jpg',
        }, {
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/38.jpg',
        }, {
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/39.jpg',
        }, {
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/40.jpg',
        }, {
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/41.jpg',
        }, {
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/42.jpg',
        }, {
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/43.jpeg',
        }, {
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/44.jpg',
        }, {
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/45.jpg',
        }, {
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/46.jpg',
        }, {
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/47.jpg',
        }, {
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/48.jpeg',
        }, {
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/49.jpeg',
        },{
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/50.jpg',
        },{
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/51.jpg',
        },{
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/52.jpg',
        },{
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/53.jpg',
        },{
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/54.jpg',
        },{
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/55.jpg',
        },{
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/56.jpg',
        },{
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/57.jpg',
        },{
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/58.jpg',
        },{
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/59.jpeg',
        },{
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/60.jpg',
        },{
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/61.jpg',
        },{
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/62.jpg',
        },{
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/63.jpeg',
        },{
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/64.jpg',
        },{
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/65.jpg',
        },{
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/66.jpg',
        },{
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/67.jpg',
        },{
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/68.jpg',
        },{
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_heco/69.jpg',
        },
        
      ],
      description: `HECO-Schrauben este una dintre cele mai notabile companii producătoare de șuruburi din Germania. A fost fondată în 1888 și a crescut încet și sănătos. Filozofia companiei este încă foarte mult influențată de valorile dragi de către fondatorul, Karl Hettich: inventivitate, diligență și conștientizarea calității.
            Este o companie de inovatori, au picioarele plantate ferm pe podea. Așadar, puteți fi siguri că marca HECO are încă multe de oferit în viitor. Hala 1 Heco este situate in localitatea Campia Turzii, judetul Cluj, str. Laminoristilor, Nr. 159.`,
      solution: `
            Realizarea instalațiilor de curenți tari din interiorul și exteriorul imobilului cu soluția tehnică oferită, ca fiind cea mai eficientă, care constat, în realizarea traseelor și circuitelor de alimentare utilaje și tablouri trase prin jgheab și cele exterioare prin pământ în 5 etape:
            <br><br><ul class="list-check-circle primary">
            <li>Etapa 1 – Montajul de jgheab și tragerea cablurilor de secțiuni mari cu ajutorul derulatoarelor și rolelor de tragere și ghidaj;</li>
                <li>Etapa 2 – Montarea și echiparea tablourilor;</li>
                <li>Etapa 3 – Racordarea circuitelor la utilaje și în tablourile electrice;</li>
                <li>Etapa 4 – Alimentarea la TRAFO a întregii hale și realizarea profilului de pozare;</li>
                <li>Etapa 5 – Verificare, teste, probe și punerea în funcțiune a instalațiilor.</li>
            </ul>`,
    }),
    new ProjectDescription({
      category: 'cat-2',
      title: 'Hala Exella',
      images: ['assets/images/proiecte/hala_exella/1 coperta.jpg',
               'assets/images/proiecte/hala_exella/2 coperta.jpg'
      ],
      client: 'EXELLA EUROPE',
      time: '12 luni',
      gallery: [
        {
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_exella/1 coperta.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/2 coperta.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/3.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/4.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/5.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/6.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/7.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/8.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/9.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/10.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/11.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/12.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/13.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/14.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/15.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/16.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/17.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/18.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/19.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/20.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/21.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/22.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/23.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/24.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/25.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/26.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/27.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/28.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/29.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/30.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/31.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/32.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/33.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/34.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/35.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/36.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/37.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/38.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/39.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/40.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/41.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/42.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/43.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/44.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/45.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/46.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/47.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/48.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/49.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/50.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/51.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/52.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/53.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/54.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/55.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/56.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/57.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/58.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/59.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/60.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/61.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/62.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/63.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/64.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/65.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/66.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/67.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/68.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/69.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/70.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/71.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/72.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/73.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/74.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/75.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_exella/76.jpg',
        },
      ],
      description: `Exella Europe S.R.L. este liderul est-european în domeniul fotogravurii chimice și fotodecupajului în metal pentru industria producătoare de ochelari, electronică, industria automobilelor, aeronavelor, medicală și producatoare de bijuterii. Începând cu 2007 pentru gravarea plăcilor de metal a introdus sistemul digital de înaltă definitie (xmos).
      Sediul central și birourile comerciale sunt situate în Cluj-Napoca (Romania), aproape de aeroportul internațional. Uzinele de producție acoperă o suprafață de 5.000 metri pătrați având un efectiv de 90 de angajați și o producție lunară de 40 tone plăci metalice.
      `,
      solution: `Realizarea instalațiilor de curenți tari și slabi din interiorul și exteriorul halei cu soluția tehnică oferită, ca fiind cea mai eficientă, care a constat, în următoarele lucrări și etape:<br><br>
            <ul class="list-check-circle primary">
             <li>Etapa 1 – montaj de jgheab, tragerea cablurilor de secțiuni mari și mici, cu ajutorul derulatoarelor și rolelor de tragere și ghidaj, operații cu nacele;</li>
             <li>Etapa 2 – montarea aparatajelor de iluminat și semnalizare, de forță și curenți slabi;</li>
             <li>Etapa 3 – racordarea circuitelor în aparataje, tablouri, utilaje, senzori;</li>
             <li>Etapa 4 – realizarea instalațiilor din exteriorul halei;</li>
             <li>Etapa 5 – verificare, testare, probe și punerea în funcțiune a instalațiilor și sistemelor.</li>
            </ul>`,
    }),
    new ProjectDescription({
      category: 'cat-2',
      title:
        'Sistem iluminat public și balizaj în parcarea Aeroportului Internațional “Avram Iancu” Cluj-Napoca',
      images: ['assets/images/proiecte/aeroport/1-coperta.jpeg',
               'assets/images/proiecte/aeroport/2-coperta.jpeg',
               'assets/images/proiecte/aeroport/3-coperta.jpg',
               'assets/images/proiecte/aeroport/4-coperta.jpg',
      ],
      client: 'ELECTROGRUP',
      time: '2 luni',
      gallery: [
        {
          isActive: true,
          urlImage: 'assets/images/proiecte/aeroport/1-coperta.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/aeroport/2-coperta.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/aeroport/3-coperta.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/aeroport/4-coperta.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/aeroport/5.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/aeroport/6.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/aeroport/7.jpeg',
          
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/aeroport/8.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/aeroport/9.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/aeroport/10.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/aeroport/11.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/aeroport/12.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/aeroport/13.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/aeroport/14.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/aeroport/15.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/aeroport/16.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/aeroport/17.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/aeroport/18.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/aeroport/19.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/aeroport/20.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/aeroport/21.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/aeroport/22.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/aeroport/23.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/aeroport/24.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/aeroport/25.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/aeroport/26.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/aeroport/27.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/aeroport/28.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/aeroport/29.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/aeroport/30.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/aeroport/31.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/aeroport/32.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/aeroport/33.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/aeroport/34.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/aeroport/35.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/aeroport/36.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/aeroport/37.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/aeroport/38.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/aeroport/39.jpg',
        },
      ],
      description: `Aeroportul este localizat în Municipiul Cluj-Napoca, cel mai important centru urban al Transilvaniei (cu peste 320.000 de locuitori) şi plasat geografic, economic, istoric şi cultural, în centrul acestei regiuni istorice cu 7 miloane de locuitori. Aeroportul este situat pe drumul E576, la aproximativ 10 km est de centrul oraşului Cluj-Napoca şi la 12 km de gara CFR. Mărimea şi localizarea sa îl fac principalul aeroport din Transilvania (nord-vestul României). De pe Aeroportul Internațional Avram IancuCluj se operează zboruri către 42de destinații regulate spre 21țări de destinație din Europa și Orientul Mijlociu.`,
      solution: `
            Realizarea instalațiilor de iluminat și balizaj din parcarea Aeroportului Cluj, cu soluția tehnică oferită, ca fiind cea mai eficientă, care a constat, în realizarea traseelor și circuitelor de alimentare a stâlpilor de iluminat și tablouri, trase prin profile realizate în carosabil, în etapele următoare:
            <br><br><ul class="list-check-circle primary">
            <li>Etapa 1 – Tăierea betonului, piconare, săpături, realizarea cofrării, realizarea armaturii;</li>
                <li>Etapa 2 – Realizarea profilului, pozarea tuburilor și tragerea cablurilor în tub, realizarea prizelor de pământ;</li>
                <li>Etapa 3 – Fixarea căminelor de vizitare din beton, realizarea profilului, realizarea fundațiilor, refacerea carosabilului cu beton rutier;</li>
                <li>Etapa 4 – Preansamblarea stâlpilor, montarea stâlpilor pe fundații, racordarea circuitelor de iluminat în stâlpi, realizarea legăturilor în căminele de vizitare, realizarea racordurilor în tablouri;</li>
                <li>Etapa 5 – Verificare, testare, probe, punerea în funcțiune a sistemului de iluminat în ansamblu.</li>
            </ul>`,
    }),
    new ProjectDescription({
      category: 'cat-2',
      title: 'Reabilitare iluminat hala Held Fashion, Cluj-Napoca',
      images: [
        'assets/images/proiecte/held_fashion/1.jpg',
        'assets/images/proiecte/held_fashion/2.jpg',
      ],
      client: 'ELECTROGLOBAL',
      time: '3 luni',
      description: `Hala Held Fashion se află în localitatea Cluj-Napoca, județul Cluj.  Hala Held Fashion este o firmă producatoare de confecții textile cu capital olandez înființată în anul 1999 în Cluj Napoca.  Producem articole vestimentare de calitate superioară pentru femei, destinate exportului către clienții din toată lumea. Produsele poartă marca FRANK WALDER, marcă recunoscută pentru calitatea deosebită și combinația inedită între elegant și casual, pe care o abordează. Cu o experiență de 18 ani și o echipă de aproximativ 130 de angajați, contribuie semnificativ la activitatea economică a Clujului, este o companie solidă și de încredere, fapt confirmat și prin locurile I obținute în Topul Firmelor din județul Cluj, rolul de investiție de succes și de factor de asigurare a unor locuri de muncă stabile și a unor condiții de muncă la standarde europene.`,
      solution: `
      Realizarea instalațiilor de iluminat din interiorul imobilului cu soluția tehnică oferită, ca fiind cea mai eficientă, care a constat, în etapele de mai jos: <br><br>
            <ul class="list-check-circle primary">
                <li>Etapa 1 – Demontarea completă a sistemului vechi de iluminat și semnalizare; </li>
                <li>Etapa 2 – montarea jgheabului suport, montarea sufelor de ancorare și realizarea și tragerea circuitelor de iluminat;</li>
                <li>Etapa 3 – Montarea aparatelor de iluminat și accesorilor conexe lor;</li>
                <li>Etapa 4 – Verificare, testare, probe și punerea în funcțiune a sistemului de iluminat.</li>
            </ul>`,
    }),
    new ProjectDescription({
      category: 'cat-2',
      title:
        'Instalații electrice și automatizării Seau Heineken, Stație de epurare Heineken, Târgul-Mureș',
      images: ['assets/images/proiecte/heineken/1 - coperta.jpg'],
      gallery: [
        {
          isActive: true,
          urlImage: 'assets/images/proiecte/heineken/1 - coperta.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/2.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/3.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/4.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/5.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/6.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/7.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/8.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/9.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/10.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/11.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/12.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/13.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/14.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/15.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/16.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/17.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/18.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/19.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/20.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/21.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/22.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/23.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/24.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/25.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/26.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/27.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/28.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/29.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/30.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/31.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/32.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/33.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/34.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/35.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/36.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/37.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/38.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/39.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/40.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/41.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/42.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/43.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/44.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/45.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/46.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/47.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/48.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/49.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/50.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/51.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/52.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/53.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/54.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/55.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/56.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/57.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/58.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/59.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/60.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/61.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/62.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/63.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/64.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/65.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/66.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/67.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/68.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/69.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/70.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/71.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/72.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/73.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/74.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/75.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/76.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/77.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/78.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/79.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/heineken/80.jpg',
        },
      ],
      client: 'ELECTROGLOBAL',
      time: '8 luni',
      description: `Stația de epurare Heineken se află în Târgul-Mureș, aceasta devine parte din grupul HEINEKEN în 2008.
            În 2015, Heineken România a continuat să își îmbunătățească rezultatele în cadrul agendei de sustenabilitate „Brewing a Better World”, făcând progrese importante, potrivit Raportului de Sustenabilitate pe 2015, Heineken România a atins marea majoritate a obiectivelor pe plan local. Fabrica de la Târgu-Mureș a intrat în top 5 cele mai performante unități ale Heineken în lume, din punct de vedere al consumului de apa.`,
      solution: `
            Realizarea instalațiilor de curenți tari și automatizări din interiorul și exteriorul fabricii cu soluția tehnică oferită, ca fiind cea mai eficientă, care a constat, în următoarele etape de execuție: 
            <br><br><ul class="list-check-circle primary">
            <li>Etapa 1 – Montajul de jgheab, realizarea de structură metalică suport jgheab și tragerea cablurilor de secțiuni mari și mici cu ajutorul derulatoarelor, rolelor de tragere și ghidaj;</li>
                <li>Etapa 2 – Montarea tablourilor, panourilor locale de comandă, HDMI-urilor, echipamentelor. motoarelor, actuatoarelor, aparatajelor diverse;</li>
                <li>Etapa 3 – Montarea instrumentației: senzoristică, actuatoare, regulatoare, debitmetre, convertizoare, etc;</li>
                <li>Etapa 4 – Racordarea tuturor circuitelor în tablourile electrice, în panourile repetoare, la instrumentație, la actuatoare, la motoare, la echipamente diverse;</li>
                <li>Etapa 5 – Etichetare, verificare, testare, măsurare, probe și punerea în funcțiune a instalațiilor și sistemelor automate.</li>
            </ul>`,
    }),
    new ProjectDescription({
      category: 'cat-2',
      title: 'Hală producție IX Schaeffler România, Brașov',
      images: ['assets/images/proiecte/hala_schaeffler/1 - coperta.jpeg',
               'assets/images/proiecte/hala_schaeffler/2 - coperta.jpeg'
      ],
      gallery: [
        {
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_schaeffler/1 - coperta.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/2 - coperta.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/3.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/4.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/5.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/6.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/7.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/8.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/9.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/10.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/11.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/12.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/13.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/14.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/15.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/16.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/17.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/18.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/19.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/20.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/21.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/22.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/23.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/24.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/25.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/26.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/27.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/28.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/29.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/30.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/31.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/32.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/33.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/34.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/35.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/36.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/37.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/38.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/39.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/40.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/41.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/42.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/43.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/44.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/45.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/46.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/47.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/48.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/49.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/50.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/51.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/52.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/53.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/54.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/55.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/56.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/57.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/58.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/59.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/60.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/61.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/62.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/63.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/64.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/65.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/66.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/67.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/68.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/69.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_schaeffler/70.jpg',
        },
      ],
      client: 'ELECTROCENTER DUO',
      time: '6 luni',
      description: `Schaeffler România este una dintre cele mai mari investiţii în capacităţi de producţie ale Grupului şi produce în Braşov componente pentru industria automobilistică şi constructoare de maşini, precum şi pentru industria eoliană. Înfiinţată în anul 2002, Schaeffler Romania prevedea realizarea unei fabrici cu 3 hale de producţie cu o suprafaţă totală de 55.000 mp. După o investiţie ce depăşeşte 500 milioane euro, fabrica ocupă o suprafaţă de 42,5 hectare, din care mai bine de 140.000 de metri pătraţi construiţi, suprafață pe care se regăsesc cele 6 hale de producţie, în Cristian, unde are loc activitatea de producţie.
            <br><br>De la înființarea sa din 2002 și până acum, Schaeffler România s-a dezvoltat și a adăugat obiective importante în portofoliul său, printre care amintim de: inagurarea primei școli profesionale în sistem dual din România, Școala Profesională Germană Kronstadt; Inaugurarea Centrului de Inginerie Schaeffler, dedicat cercetării și dezvoltării de produse noi, iar din 2019 un nou Centru de Testare Schaeffler.`,
      solution: `
            Realizarea instalațiilor de curenți tari și slabi din interiorul și exteriorul halei cu soluția tehnică oferită, ca fiind cea mai eficientă, care a constat, în următoarele lucrări și etape:
            <br><br><ul class="list-check-circle primary">
            <li>Etapa 1 – Montaj de jgheab, tragerea cablurilor de secțiuni mari și mici, cu ajutorul derulatoarelor și rolelor de tragere și ghidaj, operații cu nacele;</li>
                <li>Etapa 2 – Montarea aparatajelor de iluminat și semnalizare, de forță și curenți slabi;</li>
                <li>Etapa 3 – Racordarea circuitelor în aparataje, tablouri, utilaje, senzori;</li>
                <li>Etapa 4 – Realizarea instalațiilor din exteriorul halei;</li>
                <li>Etapa 5 – Verificare, testare, probe și punerea în funcțiune a instalațiilor și sistemelor.</li>
            </ul>`,
    }),
    new ProjectDescription({
      category: 'cat-2',
      title: 'Hală producție VGP Park, Brașov',
      images: ['assets/images/proiecte/hala_vgp/1.jpeg'],
      gallery: [
        {
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_vgp/1.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_vgp/2.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_vgp/3.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_vgp/4.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_vgp/5.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_vgp/6.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_vgp/7.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_vgp/8.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_vgp/9.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_vgp/10.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_vgp/11.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_vgp/12.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_vgp/13.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_vgp/14.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_vgp/15.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_vgp/16.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_vgp/17.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_vgp/18.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_vgp/19.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_vgp/20.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_vgp/21.jpg',
        },
      ],
      client: 'ELECTROCENTER DUO',
      time: '6 luni',
      description:
        'VGP Park Brașov este localizat în centrul României, în orașul Brașov, la doar 166 de kilometri de capitala țării, București. Brașovul este un centru industrial important din România. Pe o suprafață de peste 220.000 m2, VGP va dezvolta aproximativ 108.000 m2 de spații logistice și industriale. Ansamblul se află în apropierea Drumului European E68, în centrul zonei industriale a Brașovului și asigură acces direct la drumul principal. Cu peste 250.000 de locuitori, numeroase școli și 9 universități',
      solution: `
            Realizarea instalațiilor de curenți tari și slabi din interiorul și exteriorul halei cu soluția tehnică oferită, ca fiind cea mai eficientă, care a constat, în urmatoarele lucrari și etape:
            <br><br><ul class="list-check-circle primary">
            <li>Etapa 1 – montaj de jgheab, tragerea cablurilor de secțiuni mari și mici, cu ajutorul derulatoarelor și rolelor de tragere și ghidaj, operații cu nacele.</li>
                <li>Etapa 2 – montarea aparatajelor de iluminat și semnalizare, de forța și curenți slabi.</li>
                <li>Etapa 3 – racordarea circuitelor în aparataje, tablouri, utilaje, senzori.</li>
                <li>Etapa 4 – realizarea instalațiilor din exteriorul halei.</li>
                <li>Etapa 5 – verificare, testare, probe și punerea în funcțiune a instalațiilor și sistemelor.</li>
            </ul>`,
    }),
    new ProjectDescription({
      category: 'cat-2',
      title: 'Parcuri fotovoltaice - Ansamblu de proiecte',
      images: ['assets/images/proiecte/parc_fotovoltaic/1 - coperta.jpeg',
               'assets/images/proiecte/parc_fotovoltaic/2 - coperta.jpg'
      ],
      gallery: [
        {
          isActive: true,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/1 - coperta.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/2 - coperta.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/3.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/4.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/5.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/6.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/7.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/8.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/9.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/10.jpeg',
        },
        {
          isActive: true,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/11.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/12.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/13.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/14.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/15.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/16.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/17.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/18.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/19.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/20.jpg',
        },
        {
          isActive: true,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/21.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/22.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/23.png',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/24.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/25.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/26.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/27.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/28.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/29.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/30.jpeg',
        },
        {
          isActive: true,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/31.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/32.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/33.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/34.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/35.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/36.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/37.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/38.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/39.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/40.jpeg',
        },
        {
          isActive: true,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/41.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/42.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/43.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/44.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/45.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/46.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/47.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/49.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/50.jpeg',
        },
        {
          isActive: true,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/51.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/52.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/53.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/54.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/55.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/56.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/57.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/58.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/59.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/60.jpg',
        },
        {
          isActive: true,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/61.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/parc_fotovoltaic/62.jpg',
        },
      ],
      client: 'NOVA POWER & GAS',
      time: '48 luni',
      showInMenu: true,
      description: `Ca furnizor de energie verde, Nova deține 2 parcuri fotovoltaice în Campia Turzii și Livezile, cu o putere instalată totală de aproximativ 10 MW și o producție anuală totală de energie de peste 10.500 MWh. Aceaste sisteme de amploare necesită o mentenanță și monitorizare atentă și periodică.`,
      solution: `In cadrul serviciilor de mentenantă preventivă sunt prevăzute vizite care acoperă multipe lucrării conform cu cele de mai jos:
            <br><br><strong>Sisteme fotovoltaice:</strong>
            <br><br><ul class="list-check-circle primary">
            <li>verificare invertoare și conexiuni-remediere defecțiuni, inlocuire defecte și/sau elemente de conetică;</li>
                <li>remedieri defecțiuni în timpul vizitei dacă acest lucru este posibil;</li>
                <li>verificare funcționalității monitorizării energiei;</li>
                <li>verificare poziție comutatoare delestabile, siguranțe automate;</li>
                <li>verificare echipamente, utilaje, aparate (zgomote, mirosuri, conectica, pamantări, tablouri electrice, invertoare, etc.);</li>
                <li>raport activitate lunară;</li>
            </ul>
            <strong>Sistem Supraveghere Video:</strong>
            <br><br><ul class="list-check-circle primary">
            <li>verificare stare NVR;</li>
                <li>verificare integritate conenxiuni;</li>
                <li>verificare funționalitate elemente de câmp;</li>
            </ul>
            <strong>Sistem Cablare Structurată:</strong>
            <br><br><ul class="list-check-circle primary">
            <li>verificare integritate conexiuni;</li>
                <li>verificare rack-ului;</li>
            </ul>
            <strong>Instalații electrice curenți tari:</strong>
            <br><br><ul class="list-check-circle primary">
                <li>verificare tablouri electrice si firide;</li>
                <li>verificare trasee alimentare echipamente;</li>
                <li>verificare consumatori / receptori;</li>
                <li>verificare, prize, fise, corpuri de iluminat, exituri și conexiuni-remediere defecțiuni, înlocuire prize defecte și/sau elemente de conectică, înlocuire becuri arse sau corpuri de iluminat dacă este cazul;</li>
                <li>remedieri defecțiuni în timpul vizitei dacă acest lucru este posibil si igenizarea TE si FIRIDE;</li>
                <li>verificare funcționare monitorizare energie;</li>
                <li>verificare poziție comutatoare delestabile, siguranțe automate. Siguranțe fuzibile, Butoane;</li>
                <li>releveu, etichetare și actualizare tablouri electrice, etichetare trasee;</li>
                <li>verificare echipamente, utilaje, aparate (zgomote, mirosuri, conectică, pământări, tablou electric, etc.).</li>
                <li>verficare funcționalitate UPS;</li>
                <li>verificare funționalitate iluminat de siguranță: decuplare alimentare primară și verificare funcționalitate pe alimentarea secundară;</li>
                <li>verificare stare sistem Iluminat consumatori normali: verificare conexiuni electrice, verificare sistem de prindere și susținere, verificare integritate CIL</li>
                <li>verificare PRAM: verificare prize 230V"</li>
                <li>verificare Tablouri Electrice: verificare șiruri de cleme, verificare funcționalitate siguranțe automate, verificarea legării la priză de pământ, curațarea tablourilor electrice de praf și alte mizerii, verificare elemente de protecție din interiorul tablourilor;</li>
            </ul>
            `,
    }),
    new ProjectDescription({
      category: 'cat-2',
      title:
        'Modernizare și schimbare destinație din hala în spații administrative și birouri, Cluj-Napoca',
      images: ['assets/images/proiecte/hala_est/1 - coperta.jpeg',
               'assets/images/proiecte/hala_est/2 - coperta.jpeg'
      ],
      gallery: [
        {
          isActive: true,
          urlImage: 'assets/images/proiecte/hala_est/1 - coperta.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/2 - coperta.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/3.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/4.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/5.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/6.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/7.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/8.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/9.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/10.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/11.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/12.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/13.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/14.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/15.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/16.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/17.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/18.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/19.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/20.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/21.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/22.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/23.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/24.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/25.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/26.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/27.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/28.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/29.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/30.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/31.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/32.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/33.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/34.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/35.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/36.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/37.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/38.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/39.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/40.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/41.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/42.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/43.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/44.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/45.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/46.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/47.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/48.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/49.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/50.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/51.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/52.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/53.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/54.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/55.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/56.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/57.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/58.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/59.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/60.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/61.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/62.jpg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/63.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/64.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/65.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/66.jpeg',
        },
        {
          isActive: false,
          urlImage: 'assets/images/proiecte/hala_est/67.jpeg',
        },
      ],
      client: 'EST COMPANY ROM',
      time: '15 luni',
      description: `EST COMPANY ROM IMPEX a fost înfiinţată în 1996 în Cluj Napoca, are 100 angajaţi şi este specializată în producţia de articole de haine şi alte produse din materiale textile uşoare. Clienţii sunt branduri de renume din Europa şi America. Pune accent pe echiparea firmei cu utilaje performante, care să contribuie la ridicarea eficienţei şi calităţii execuţiei. Dispun de staţii de proiectare/digitizare încadrare performante Gemini şi Gerber, de utilaje de spanuit şi croit de mare eficienţă şi de întreaga gamă de maşini de cusut necesare. La toate acestea aportul nostru a venit în re-echiparea și remodernizarea complete a întregii fabrici cu instalații noi de curenți tari și slabi.`,
      solution: `
            Realizarea instalațiilor de curenți tari și slabi din interiorul și exteriorul halei cu soluția tehnică oferită, ca fiind cea mai eficientă, care a constat, în urmatoarele lucrări și etape:
            <br><br><ul class="list-check-circle primary">
            <li>Etapa 1 – montajul de jgheab, tragerea cablurilor de secțiuni mari și mici, cu ajutorul derulatoarelor și rolelor de tragere și ghidaj;</li>
                <li>Etapa 2 – montarea aparatajelor de iluminat și semnalizare, forță și curenți slabi;</li>
                <li>Etapa 3 – racordarea circuitelor în aparataje, tablouri, utilaje. senzori și sisteme;</li>
                <li>Etapa 4 – realizarea instalațiilor din exteriorul halei;</li>
                <li>Etapa 5 – verificare, testare, probe și punerea în funcțiune a instalațiilor și sistemelor.</li>
            </ul>`,
    }),
    new ProjectDescription({
      category: 'cat-3',
      title: 'Reamenajare spațiu comercial Levi’s',
      images: ['assets/images/proiecte/iulius/1 coperta.jpg',
               'assets/images/proiecte/iulius/2 coperta.jpeg',
               'assets/images/proiecte/iulius/3 coperta.jpg'

      ],
      gallery: [
        { isActive: true, urlImage: 'assets/images/proiecte/iulius/1 coperta.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/2 coperta.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/3 coperta.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/4.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/5.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/6.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/7.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/8.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/9.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/10.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/11.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/12.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/13.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/14.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/15.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/16.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/17.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/18.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/19.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/20.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/21.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/22.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/23.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/24.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/25.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/26.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/27.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/28.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/29.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/30.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/31.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/32.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/33.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/34.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/35.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/36.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/37.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/38.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/39.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/40.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/41.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/42.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/43.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/44.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/45.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/iulius/46.jpeg' },
      ],
      client: 'GREEN SEAS INTERNATIONAL MAINTENANCE',
      time: '2 luni',
      description: `Brandul Levi's a inaugurat un magazin de haine situat în Cluj-Napoca - Str. Alexandru Vaida Voievod, 53, Iulius Mall cu o suprafață de peste 130 mp, propune o mare varietate de piese vestimentare create pentru a răspunde stilului diferitelor generații. Acest magazin este unul dintre cele 7 magazine din România
            Situat la etajul al doilea din Iulius Mall Cluj, magazinul își întâmpină clienții într-un spațiu prietenos, reprezentativ pentru stilul casual pe care îl promovează. Design-ul este modern, cu influențe industriale, completând perfect colecțiile din raft și oferind un vibe relaxat. Încă de la lansarea de către Levi Strauss & Co în1873, blugii Levi's® au devenit unul dintre cele mai recunoscute articole de îmbrăcăminte din lume – captând atenția și loialitatea oamenilor de-a lungul generațiilor. Astăzi, gama de jeans și accesorii este disponibilă în mai mult de110 țări, iar în România s-a inaugurat cel de al treisprezecelea magazin.`,
      solution: `
            Realizarea instalațiilor de curenți tari și slabi din interiorul și exteriorul magazinului cu soluția tehnică oferită, ca fiind cea mai eficientă, care a constat, în urmatoarele lucrări și etape:
            <br><br><ul class="list-check-circle primary">
            <li>Etapa 1 – montaj de jgheab, tragerea cablurilor și a circuitelor de secțiuni mici;</li>
                <li>Etapa 2 – montarea aparatajelor de iluminat și semnalizare, de forța și curenți slabi;</li>
                <li>Etapa 3 – racordarea circuitelor în aparataje, tablouri, utilaje. senzori și sisteme;</li>
                <li>Etapa 4 – realizarea instalaților din exteriorul magazinului: reclamă luminoasă;</li>
                <li>Etapa 5 – verificare, testare, probe și punerea în funcțiune a instalațiilor și sistemelor.</li>
            </ul>`,
    }),
    new ProjectDescription({
      category: 'cat-3',
      title: 'Show room și hala producție Casa Pietrei',
      images: ['assets/images/proiecte/stone/1-coperta.jpg',
               'assets/images/proiecte/stone/2-coperta.jpeg'
      ],
      gallery: [
        { isActive: true, urlImage: 'assets/images/proiecte/stone/1-coperta.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/2-coperta.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/3.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/4.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/5.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/6.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/7.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/8.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/9.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/10.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/12.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/13.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/14.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/15.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/16.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/17.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/18.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/19.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/20.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/21.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/22.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/23.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/24.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/25.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/26.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/27.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/28.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/29.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/30.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/31.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/32.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/33.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/34.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/35.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/36.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/37.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/38.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/39.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/40.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/41.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/42.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/43.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/44.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/45.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/46.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/47.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/48.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/49.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/50.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/51.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/52.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/53.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/stone/54.jpg' },
      ],
      client: 'STONE OUTLET',
      time: '6 luni',
      description: `Stone Outlet a inaugurat un magazin (https://casapietrei.ro/) nou de prelucrarea pietrei - “Casa Pietrei” din Cluj-Napoca - Str. Traian Vuia, 407039, cu o suprafață de 130 mp, propune o mare varietate de piese de piatra create pentru a răspunde stilurilor diferite. Magazinul își întâmpină clienții într-un spațiu prietenos, reprezentativ pentru stilul pe care îl promovează. Design-ul este modern, cu influențe industriale.`,
      solution: `
            Realizarea instalațiilor de curenți tari și slabi din interiorul și exteriorul magazinului cu soluția tehnică oferită, ca fiind cea mai eficientă, care a constat, în urmatoarele lucrări și etape:
            <br><br><ul class="list-check-circle primary">
            <li>Etapa 1 – montaj de jgheab, tragerea cablurilor și a circuitelor de secțiuni mici;</li>
                <li>Etapa 2 – montarea aparatajelor de iluminat și semnalizare, de forța și curenți slabi;</li>
                <li>Etapa 3 – racordarea circuitelor în aparataje, tablouri, utilaje, senzori și sisteme;</li>
                <li>Etapa 4 – realizarea instalațiilor din exteriorul magazinului: reclamă luminoasă, supraveghere, iluminat, bransament;</li>
                <li>Etapa 5 – verificare, testare, probe și punerea în funcțiune a instalațiilor și a sistemelor.</li>
            </ul>`,
    }),
    new ProjectDescription({
      category: 'cat-3',
      title: 'Prajitorie Coffee Cup',
      images: ['assets/images/proiecte/coffe_cup/1 coperta.jpg',
               'assets/images/proiecte/coffe_cup/2 coperta.jpg'
      ],
      gallery: [
        { isActive: true, urlImage: 'assets/images/proiecte/coffe_cup/1 coperta.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/coffe_cup/2 coperta.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/coffe_cup/3.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/coffe_cup/4.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/coffe_cup/5.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/coffe_cup/6.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/coffe_cup/7.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/coffe_cup/8.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/coffe_cup/9.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/coffe_cup/10.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/coffe_cup/11.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/coffe_cup/12.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/coffe_cup/13.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/coffe_cup/14.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/coffe_cup/15.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/coffe_cup/16.jpg' },
      ],
      client: 'COFFEE CUP',
      time: '1 luna',
      description: `Începând inițial cu un singur coffee shop în Cluj-Napoca, evoluția Coffe Cup a fost marcată de curiozitate, inovație și creativitate. Aceste lucruri s-au regăsit în fiecare etapă a drumului pe care îl parcurge bobul de cafea până să ajungă în ceașcă – începând de la alegerea responsabilă a fermelor producătoare și până la pasiunea cu care este preparată cafeaua. Mai mult de atât, din dorința de a scoate în evidență profilul fiecărei origini de cafea care ajunge pe rafturile noaste, în 2021 am unaugurat propria prăjutorie de cafea în Cluj-Napoca. Astfel fiecare locație din cele trei orașe în care este prezentat brandul se bucură de o cafea de specialitate proaspăt prăjită.`,
      solution: `
      Realizarea instalațiilor de curenți tari și slabi din interiorul și exteriorul magazinului cu soluția tehnica oferită, ca fiind cea mai eficientă, care a constat, în urmatoarele lucrari și etape:
            <br><br>
            <ul class="list-check-circle primary">
              <li>Etapa 1 – montarea aparatajelor de iluminat și semnalizare, de forță și curenți slabi;</li>
              <li>Etapa 2 – racordarea circuitelor în aparataje, tablouri, utilaje. senzori și sisteme;</li>
              <li>Etapa 3 – realizarea instalațiilor din exteriorul magazinului: reclamă luminoasă;</li>
              <li>Etapa 4 – Verificare, testare, probe si punerea in functiune a instalatiilor si sistemelor.</li>
            </ul>`,
    }),
    new ProjectDescription({
      category: 'cat-4',
      title: 'Eficiența energetică hală producție și birouri',
      images: ['assets/images/proiecte/hala_koepp/1.jpeg'],
      gallery: [
        { isActive: true, urlImage: 'assets/images/proiecte/hala_koepp/1.jpeg' },
        { isActive: false, urlImage: 'assets/images/proiecte/hala_koepp/10.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/hala_koepp/11.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/hala_koepp/2.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/hala_koepp/3.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/hala_koepp/4.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/hala_koepp/5.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/hala_koepp/6.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/hala_koepp/7.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/hala_koepp/8.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/hala_koepp/9.jpg' },
      ],
      client: 'KOEPP ROMÂNIA',
      time: '8 luni',
      description: `Koepp Romania SRL o companie din Maramureș, str. Principală, nr. 275E, Sat Ariniș, cu activitatea principală de fabricare a articolelor din cauciuc își desfasoară activitate în cadrul unei hale industrial de o calitate premium. Activitatea o desfasoară în condiții de eficiența energetică relativ înaltă. `,
      solution: `
            Soluțiile tehnologice oferite de noi în cadrul Koepp România valorifică puterea datelor, IoT și a inteligenței artificiale (AI) prin combinarea unui hardware puternic cu un software intuitiv via cloud pentru a aduce eficiența energetică la un procent ridicat.
            Am adus economii de energie ca soluție (ESaaS) care au un impact direct asupra capitalului clientului, dar la ZERO capital contribuție din partea clientului.
            <br><br><strong>Soluțiile principale de eficiență și retehnologizare implementate și aduse în cadrul Koepp România sunt: </strong>
            <br><br><ul class="list-check-circle primary">
                <li>Soluții de eficiență energetică, făcând risipa de energia invizibilă, vizibilă prin intermediul tehnologiei noastre și prin îmbunătățirea proceselor operaționale - (15% la 25% aport eficiență)</li>
                <li>Soluții solare de generare a energiei verzi care să întrunească cerintele clientului și să securizeze pe termen lung competivitatea prețului - (30% la 50% aport eficiență)</li>
            </ul>`,
    }),
    new ProjectDescription({
      category: 'cat-4',
      title: 'Eficiență energetică hală producție și birouri',
      images: ['assets/images/proiecte/zelpast/1.jpg'],
      gallery: [
        { isActive: true, urlImage: 'assets/images/proiecte/zelpast/1.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/zelpast/2.jpg' },
      ],
      client: 'ZELLPLAST RO',
      time: '8 luni',
      description: `Zellplast RO SRL o companie din Maramureș, str. Principală, nr. 275E, Sat. Ariniș, cu activitatea principală de fabricarea placilor, foliilor, tuburilor și profilelor din material plastic, își desfasoară activitate în cadrul unei hale industriale de o calitate premium. Activitatea o desfașoara în condiții de eficiență energetică relativ înaltă. `,
      solution: `
            Soluțiile tehnologice oferite de noi în cadrul Zellplast Ro valorifică puterea datelor, IoT și a inteligenței artificiale (AI) prin combinarea unui hardware puternic cu un software intuitiv via cloud pentru a aduce eficiența energetică la un procent ridicat.
            Am adus economii de energie ca Soluție (ESaaS) care au un impact direct asupra capitalului clientului, dar la ZERO capital contribuție din partea clientului.
            <br><br><strong>Soluțiile principale de eficiență și retehnologizare implementate și aduse în cadrul Zellplast Ro sunt:</strong>
            <br><br><ul class="list-check-circle primary">
                <li>Soluții de eficiență energetică, facând risipă de energia invizibilă, vizibilă prin intermediul tehnologiei noastre și prin îmbunătățirea proceselor operaționale - (15% la 25% aport eficiență)</li>
                <li>Soluții solare de generare a energiei verzi care să întrunească cerintele clientului și să securizeze pe termen lung competivitatea prețului - (30% la 40% aport eficiență)</li>
            </ul>`,
    }),
    new ProjectDescription({
      category: 'cat-4',
      title: 'Eficiență energetică hală producție și birouri',
      images: [
        'assets/images/proiecte/hala_flexibil/2.jpg',
        'assets/images/proiecte/hala_flexibil/1.png',
      ],
      gallery: [
        { isActive: true, urlImage: 'assets/images/proiecte/hala_flexibil/1.png' },
        { isActive: false, urlImage: 'assets/images/proiecte/hala_flexibil/2.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/hala_flexibil/4.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/hala_flexibil/5.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/hala_flexibil/6.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/hala_flexibil/7.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/hala_flexibil/8.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/hala_flexibil/9.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/hala_flexibil/10.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/hala_flexibil/11.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/hala_flexibil/12.jpg' },
      ],
      client: 'FLEXIBIL',
      time: '8 luni',
      description: `Flexibil este o companie din Maramureș, str. Sub Dura, nr. 275E, Sat. Săsar, cu activitatea principală de producție de piese și accesorii pentru autovehicule și pentru motoare de autovehicule își desfasoară activitate în cadrul unei hale industriale de o calitate premium. Activitatea o desfașoara în condiții de eficiență energetică relativ înaltă. `,
      solution: `
            Am adus economii de energie ca soluție (ESaaS) care au un impact direct asupra capitalului clientului, dar la ZERO Capital contribuție din partea clientului.
            <br><br><strong>Soluțiile principale de eficiență și retehnologizare implementate și aduse în cadrul Flexibil SRL sunt:</strong>
            <br><br><ul class="list-check-circle primary">
                <li>Soluții solare de generare a energiei verzi care să intrunească cerintele clientului și să securizeze pe termen lung competivitatea prețului - (30% la 60% aport eficiență)</li>
            </ul>`,
    }),
    new ProjectDescription({
      category: 'cat-4',
      title: 'Eficiență energetică imobil mixt',
      images: [
        'assets/images/proiecte/eficiența_energetica_coratim/2.jpg'
      ],
      gallery: [
        { isActive: true, urlImage: 'assets/images/proiecte/eficiența_energetica_coratim/1.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/eficiența_energetica_coratim/2.jpg' },
      ],
      client: 'CORATIM',
      time: '8 luni',
      description: `Coratim o companie din Cluj Napoca, str. Campul Pâini 3-5C, cu activitatea principală de cumpararea și vânzarea de bunuri imobiliare proprii își desfasoara activitate în cadrul unui imobil de o calitate premium. Activitatea o desfașoară în condiții de eficiență energetică relativ înaltă.`,
      solution: `
            Am adus economii de energie ca soluție (ESaaS) care au un impact direct asupra capitalului clientului, dar la ZERO capital contribuție din partea clientului.
            <br><br><strong>Soluțiile principale de eficiență și retehnologizare implementate și aduse în cadrul Coratim sunt:</strong>
            <br><br><ul class="list-check-circle primary">
                <li>Soluții solare de generare a energiei verzi care să întrunească cerințele clientului și să securizeze pe termen lung competivitatea prețului - (30% la 60% aport eficiență)</li>
            </ul>`,
    }),
    new ProjectDescription({
      category: 'cat-4',
      title: 'Eficiența energetică hala producție și birouri',
      images: [
        'assets/images/proiecte/eficiență_energetica_csi_ro/1.jpg'
      ],
      client: 'CSI ROMANIA',
      gallery: [
        { isActive: true, urlImage: 'assets/images/proiecte/eficiență_energetica_csi_ro/1.jpg' },
        { isActive: false, urlImage: 'assets/images/proiecte/eficiență_energetica_csi_ro/2.jpg' },
      ],
      time: '8 luni',
      description: `CSI Romania o companie de pe str. Bulevardul Muncii 12, Cluj-Napoca, cu activitatea principală de Fabricarea echipamentelor de ridicat și manipulat, își desfășoară activitatea în cadrul unei hale industriale de o calitate premium. Activitatea o desfășoară în condiții de eficiență energetică relativ înaltă.`,
      solution: `Soluțiile tehnologice oferite de noi în cadrul CSI Romania, valorifică puterea datelor, IoT și a inteligenței artificiale (AI) prin combinarea unui hardware puternic cu un software intuitiv via cloud pentru a aduce eficiența energetică la un procent ridicat.
                Am adus economii de energie ca Soluție (ESaaS) care au un impact direct asupra capitalului clientului, dar la ZERO capital contribuție din partea clientului.
            <br><br><strong>Soluțiile principale de eficiență și retehnologizare implementate aduse în cadrul CSI Romania sunt:</strong>
            <br><br><ul class="list-check-circle primary">
            <li>Soluții de eficiență energetică, făcând risipă de energia invizibilă, vizibilă prin intermediul tehnologiei noastre și prin îmbunătățirea proceselor operaționale - (15% la 25% aport eficiență)</li>
                <li>Soluții solare de generare a energiei verzi care să întrunească cerințele clientului și să securizeze pe termen lung competitivitatea prețului - (30% la 40% aport eficiență)</li>
            </ul>`,
    }),
  ];
}

export class ProjectDescription {
  category: string;
  category_name: string;
  images: string[];
  title: string;
  client: string;
  isActive: boolean = true;
  time: string;
  showInMenu: boolean = false;
  description: string;
  gallery: PhotoRoute[];
  solution: string;

  public constructor(init?: Partial<ProjectDescription>) {
    Object.assign(this, init);
    this.setCategoryName();
  }

  private setCategoryName() {
    switch (this.category) {
      case 'cat-1':
        this.category_name = 'Instalații civile';
        break;
      case 'cat-2':
        this.category_name = 'Instalații industriale';
        break;
      case 'cat-3':
        this.category_name = 'Instalații comerciale';
        break;
      case 'cat-4':
        this.category_name = 'Eficiență energetică';
        break;
    }
  }
}
